import React, { useContext } from 'react'
import { Button, Box } from '@smooth-ui/core-sc'
import { breakpoints } from '@xstyled/system'
import { Link, Redirect } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import styled, { css } from 'styled-components'
import { gql } from 'apollo-boost'

import PageWrapper, { ContentWrapper } from 'components/PageWrapper/PageWrapper'
import { PageTitle, SubTitle, P, Row, Col } from 'components/Elements/index'
import routeConfig from 'routeConfig'
import TokenContext from 'helpers/tokenContext'
import { LoadingSpinnerFullscreen } from 'components/Spinner/Spinner'
import TrainingTypes from '../Posts/TrainingTypes'

import config from 'config'

const query = gql(`
  query Page($pageId: Int!) {
    pageBy(pageId: $pageId) {
      title
      content
      homePage {
        banners {
          title
          buttonText
          textBelow
          text
          imageFull: image {
            sourceUrl(size: SLIDES)
          }
          image: image {
            sourceUrl(size: SLIDES_THUMB)
          }
        }
        features {
          title
          text
          image {
            sourceUrl(size: LARGE)
            mediaDetails {
              height
              width
            }
            altText
          }
          v2Url
          v2BtnLabel
        }
      }
    }
  }
`)

const Home = () => {
  const { token } = useContext(TokenContext)
  const { data, loading } = useQuery(query, {
    variables: {
      pageId: config.frontPageId
    }
  })
  if (token) {
    return <Redirect to={routeConfig.posts} />
  }
  if (loading && !data) {
    return <LoadingSpinnerFullscreen />
  }
  const {
    pageBy: {
      title,
      homePage: { banners, features }
    }
  } = data
  const banner = banners && banners[0]
  const first = features && features[0]
  const seconds = features.filter((item, i) => i > 0 && i < 5)
  return (
    <PageWrapper title={title} widthContentWrapper={false}>
      {banner && (
        <Banner
          mb={40}
          image={banner.image.sourceUrl}
          data-image-hd={banner.imageFull.sourceUrl}
        >
          <PageTitle fontSize={{ sm: '3.1rem', xs: '2rem' }} mb={1}>
            {banner.title}
          </PageTitle>
          <P mb={3} fontSize="1.2rem">
            {banner.text}
          </P>
          <Button
            scale="lg"
            as={Link}
            to={routeConfig.signup}
            fontWeight="700"
            px={{ sm: 40 }}
          >
            {banner.buttonText}
          </Button>
          <P pt={2} mb={0}>
            {banner.textBelow}
          </P>
        </Banner>
      )}
      <ContentWrapper>
        <Box textAlign="center" mb={40}>
          <SubTitle fontSize="2rem" mb={0}>
            {first.title}
          </SubTitle>
          <P fontSize="1.4rem">{first.text}</P>
        </Box>
        <Box mb={50}>
          <TrainingTypes withTitle={false} />
        </Box>
        {seconds &&
          seconds.map(({ title, image, text, v2Url, v2BtnLabel }, i) => (
            <Section
              key={i}
              title={title}
              text={text}
              image={
                image ? (
                  <Img
                    src={image.sourceUrl}
                    width={image.mediaDetails.width}
                    height={image.mediaDetails.height}
                    alt={image.altText}
                  />
                ) : null
              }
              link={v2Url}
              btn={v2BtnLabel}
              alt={i % 2 === 1}
            />
          ))}
      </ContentWrapper>
      {banner && (
        <Box backgroundColor="#232323" color="white" pt="1rem" pb="1rem">
          <ContentWrapper>
            <Row alignItems="center">
              <Col col={{ xs: 1, sm: 2 / 3 }}>
                <div>
                  <SubTitle fontSize="2rem" mb={1}>
                    {banner.title}
                  </SubTitle>
                  <P fontSize="1.4rem" maxWidth="40rem" mb={4}>
                    {banner.text}
                  </P>
                </div>
              </Col>
              <Col col={{ xs: 1, sm: 1 / 4 }} textAlign="center">
                <Button
                  as={Link}
                  to={routeConfig.signup}
                  width="100%"
                  fontWeight="bold"
                  scale="lg"
                >
                  {banner.buttonText}
                </Button>
              </Col>
            </Row>
          </ContentWrapper>
        </Box>
      )}
    </PageWrapper>
  )
}

const Img = styled.img`
  display: block;
  margin-bottom: 1.5rem;
`

const Section = ({ title, text, image, link, btn, alt = false }) => (
  <Box mb={50}>
    <Row alignItems="center">
      <Col col={{ xs: 1, sm: 1 / 2 }} order={{ xs: 2, sm: alt ? 2 : 1 }}>
        <Box mb={4}>
          <SubTitle textAlign="center" fontSize="1.8rem" mb={2}>
            {title}
          </SubTitle>
          <P textAlign="center" fontSize="1.4rem">
            {text}
          </P>
          {link && (
            <Box pt={3} textAlign="center">
              <Button as={Link} to={link} fontWeight="bold" scale="lg">
                {btn || 'Les mer'}
              </Button>
            </Box>
          )}
        </Box>
      </Col>
      <Col col={{ xs: 1, sm: 1 / 2 }} order={{ xs: 1, sm: alt ? 1 : 2 }}>
        <div>{image}</div>
      </Col>
    </Row>
  </Box>
)

const BannerBg = styled(Box)(
  breakpoints({
    xs: css`
      background-color: #000;
      background-position: top center;
      background-size: cover;
      background-image: url(${p => p.image});
      padding-top: 8rem;
      padding-bottom: 2rem;
      color: white;
      & h1 {
        font-weight: 900;
        font-style: italic;
        text-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
      }
      & h1,
      & p {
        max-width: 40rem;
      }
      & p {
        font-weight: 400;
        text-shadow: 0 0 0.5rem rgba(0, 0, 0, 1);
      }
      & a {
        border-radius: 2px;
      }
    `,
    md: css`
      background-image: url(${p => p['data-image-hd']});
      padding-top: 12rem;
      padding-bottom: 3rem;
    `
  })
)

const Banner = ({ children, ...props }) => (
  <BannerBg {...props}>
    <ContentWrapper>{children}</ContentWrapper>
  </BannerBg>
)

export default Home
