import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { breakpoints } from '@xstyled/system'

import { fixTitle } from 'helpers/entities'

const FavoriteItemBg = styled.div`
  background: ${p => p.theme.colors.gray1};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  ${p =>
    p.image
      ? `
    background-image: url(${p.image});
    background-size: cover;
    background-position: center center;
  `
      : `
    
  `}
  ${p =>
    p.asTitle
      ? `
    align-items: center;
    justify-content: center;
  `
      : `
    align-items: flex-end;
    & strong {
      padding: 0.5rem;
      display: block;
      background: rgba(0,0,0,.3);
      color: #fff;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `}
`

const FavoriteItemContainer = styled(Link)`
  background: ${p => p.theme.colors.gray1};
  width: 100%;
  display: block;
  position: relative;
  padding-top: 60%;
  margin-bottom: 1rem;
  &:hover,
  &:focus,
  &:active {
    ${FavoriteItemBg},
    strong {
      background-color: ${p => p.theme.colors.primary};
      color: #fff;
    }
  }
`

const FavoriteItem = ({ to, title, image, asTitle }) => (
  <FavoriteItemContainer to={to}>
    <FavoriteItemBg image={image} asTitle={asTitle}>
      <strong>{title}</strong>
    </FavoriteItemBg>
  </FavoriteItemContainer>
)

const ScrollWrapper = styled.div`
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`

const ScrollItem = styled.article(
  breakpoints({
    xs: css`
      flex: 0 0 auto;
      width: 10rem;
      margin-right: 1rem;
    `,
    md: css`
      width: 14.8rem;
    `
  })
)

const ScrollList = ({ items, title, to, backstate, withTitle = true }) => {
  return (
    <ScrollWrapper>
      {withTitle && (
        <ScrollItem>
          <FavoriteItem title={title} to={to} asTitle />
        </ScrollItem>
      )}
      {items &&
        items.map(({ title, pathname, featuredImage }, i) => {
          const postTitle = fixTitle(title)
          return (
            <ScrollItem key={i}>
              <FavoriteItem
                title={postTitle}
                to={{
                  pathname,
                  state: {
                    postTitle,
                    ...backstate
                  }
                }}
                image={featuredImage ? featuredImage.sourceUrl : null}
              />
            </ScrollItem>
          )
        })}
    </ScrollWrapper>
  )
}

export default ScrollList
