import React from 'react'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

import ScrollList from 'components/ScrollList/ScrollList'
import routeConfig from 'routeConfig'

const query = gql`
  query Page($slug: String!) {
    pageBy(uri: $slug) {
      navigationPage {
        menuItems {
          title
          text
          image {
            sourceUrl(size: LIST)
          }
          categorylink {
            __typename
            ... on Category {
              slug
            }
          }
        }
      }
    }
  }
`

const FavoritesList = props => {
  const { data } = useQuery(query, {
    variables: {
      slug: routeConfig.traningTypes
    }
  })
  const items =
    data && data.pageBy && data.pageBy.navigationPage.menuItems.length
      ? data.pageBy.navigationPage.menuItems.map(item => ({
          ...item,
          pathname: `${routeConfig.category}/${item.categorylink[0].slug}`,
          featuredImage: item.image
        }))
      : null
  return (
    <ScrollList
      {...props}
      items={items}
      title="Treningsformer"
      to={routeConfig.traningTypes}
      backstate={{
        fromUrl: routeConfig.traningTypes,
        fromName: 'Treningsformer'
      }}
    />
  )
}

export default FavoritesList
